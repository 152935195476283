<template>
  <div class="notifyComponent">
    <el-form ref="form" label-position="top" :model="value" size="mini">
      <el-form-item class="required" :label="$locale.notify_editor.label.name">
        <el-input v-model="value.name"></el-input>
      </el-form-item>

      <el-form-item class="required" :label="$locale.notify_editor.label.relates_registry">
        <el-select
        value-key="id"
        v-model="value.object_id"
        filterable
        size="mini">
          <el-option
            v-for="item in listRelatesRegistry"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <div class="form-group-item">
        <el-form-item class="required" :label="$locale.notify_editor.label.creation_condition">
          <el-select
            value-key="id"
            v-model="value.condition_type"
            size="mini">
            <el-option
              v-for="item in listCondition"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-select
            value-key="id"
            v-model="value.condition_match_type"
            size="mini">
            <el-option
              v-for="item in listMathcType"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="isShowListStates">
          <el-select
            value-key="id"
            multiple
            collapse-tags
            filterable
            v-model="value.notification_condition_states"
            size="mini">
            <el-option
              v-for="item in listState"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>

      <!-- <el-form-item v-if="!isDsl" :label="$locale.notify_editor.label.title">
        <el-input type="textarea" readonly :autosize="{ minRows: 2, maxRows: 4}" v-model="innerFormula.titleValue"></el-input>
        <el-button type="primary" @click="calcTitle($locale.notify_editor.label.title)" plain size="small">{{$locale.notify_editor.label.calc}}</el-button>
      </el-form-item> -->
      <div class="formula-box">
        <h4>{{$locale.notify_editor.label.title}}</h4>
        <div contenteditable="false"  readonly ref="titleTextarea" class="row_formulas__edit" placeholder="Формула">
        </div>
        <el-button :disabled="dsbBtn" type="primary" @click="calcTitle($locale.notify_editor.label.title, 'title')" plain size="small">{{$locale.notify_editor.label.calc}}</el-button>
      </div>

      <!-- <el-form-item :label="$locale.notify_editor.label.main">
        <el-input type="textarea" readonly :autosize="{ minRows: 2, maxRows: 4}" v-model="value.content_formula_id"></el-input>
        <el-button type="primary" @click="calcTitle($locale.notify_editor.label.main)" plain size="small">{{$locale.notify_editor.label.calc}}</el-button>
      </el-form-item> -->
      <div class="formula-box">
        <h4>{{$locale.notify_editor.label.main}}</h4>
        <div contenteditable="false"  readonly ref="contentTextarea" class="row_formulas__edit" placeholder="Формула">
        </div>
        <el-button :disabled="dsbBtn" type="primary" @click="calcTitle($locale.notify_editor.label.main, 'content')" plain size="small">{{$locale.notify_editor.label.calc}}</el-button>
      </div>

      <div class="form-group-item">
        <el-form-item class="required" :label="$locale.notify_editor.label.author">
            <author
              v-if="listField.length > 0"
              :model.sync="value.author_field_id"
              :value="value"
              :listStatesAroundUser='listStatesAroundUser'
              :listField='listField'
              :listUser='listUser'>
            </author>
        </el-form-item>
      </div>

      <div class="form-group-item">
        <el-form-item class="required" :label="$locale.notify_editor.label.send">
            <recipient
              v-if="listField.length > 0"
              :value="value"
              :listStatesAroundUser='listStatesAroundUser'
              :listField='listField'
              :listUser='listUser'
              :listMathcType='listMathcType'>
            </recipient>
        </el-form-item>
      </div>

      <el-form-item class="required" :label="$locale.notify_editor.label.period" style="margin-top: 10px">
        <el-select
          value-key="id"
          v-model="value.period_type_id"
          size="mini">
          <el-option
            v-for="item in periods"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$locale.notify_editor.label.choose_icon" style="margin-top: 10px">
        <el-select clearable size="mini" v-model="value.icon">
          <el-option
            v-for="item in listTag"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right;">
              <i style="font-size: 30px;width: 22px;height: 22px;" :class="item.value"></i>
            </span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$locale.notify_editor.label.ring_to_email" style="margin-top: 10px">
        <email-type
          v-if="listField.length > 0"
          :value="value"
          :listField='listField'>
        </email-type>
      </el-form-item>

      <el-form-item :label="$locale.notify_editor.label.interactive" style="margin-top: 10px">
        <el-select
          value-key="id"
          v-model="value.interaction_type"
          clearable
          size="mini">
          <el-option
            v-for="item in listInteractive"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item style="margin-top: 10px">
        <el-checkbox v-model="value.shows_popup_message">{{$locale.notify_editor.label.message}}</el-checkbox>
      </el-form-item>
    </el-form>
    <el-dialog
      :visible.sync="dialogNotify"
      width="45%"
      :modal="true"
      :top='"15"'>
      <column-formula
        :propslocal="propslocal"
        :value="value"
        :key="notifyFormula.type"
        :dialogNotify.sync="dialogNotify"
        :notifyFormula='notifyFormula'
        @getValueFormula='getValueFormula'></column-formula>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button style="display: none" @click="dialogNotify = false">Cancel</el-button>
        <el-button style="display: none" type="primary" @click="dialogNotify = false">Confirm</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import Registry from '@/components/LogicEditor/Models/Registry'
import States from '@/components/LogicEditor/Models/States'
import Logic from '@/components/LogicEditor/Models/Logic'
// import Users from '@/components/AccessEditor/Models/User'
import Author from '@/components/NotificationEditor/components/notify/author'
import Recipient from '@/components/NotificationEditor/components/notify/recipient'
import EmailType from '@/components/NotificationEditor/components/notify/emailType'
import Icons from '@/components/MenuEditor/components/el-icons'
import columnFormula from '@/components/LogicEditor/components/column_formula'
import FormulasHttp from '@/components/LogicEditor/Models/formulas'

// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { UserAPI } from '@/services/AccessEditor/infrastructure/api/UserAPI'
export default {
  name: 'notify',
  props: ['value', 'save'],
  components: {
    Author,
    Recipient,
    EmailType,
    columnFormula
  },
  data () {
    return {
      propslocal: {
        // новое действие
        isNewAction: true,
        // выбранная бизнес логика
        businesslogic: {},
        // список для построения subAction
        listOpenAction: null,
        // выбранное subAction
        selectSubAction: '',
        // значение полей от сервера
        getAnswer: '',
        getNotifyContent: null
      },
      notifyFormula: {
        type: null,
        isShow: true,
        name: null
      },
      listRelatesRegistry: [],
      dsbBtn: false,
      dialogNotify: false,
      listStatesAroundUser: [],
      listInteractive: [{
        id: 'open_card',
        name: 'Открыть карточку'
      }],
      listField: [],
      listState: [],
      listTag: [],
      listUser: [],
      listCondition: [
        {
          id: 'state',
          name: 'По состояниям'
        },
        {
          id: 'command',
          name: 'Внешней командой'
        }
      ],
      listMathcType: [
        {
          id: 'all',
          name: 'При соблюдении всех условий одновременно' },
        {
          id: 'any',
          name: 'При любом условии'
        }
      ],
      periods: []
    }
  },
  watch: {
    'value.object_id': {
      handler: async function (val, oldval) {
        if (oldval) {
          console.log(val)
          this.propslocal.businesslogic.registry_id = val
          let logicData = await new Logic().custom('/logics').params({ registry_id: val }).get()
          if (logicData[0]) {
            this.propslocal.businesslogic = logicData[0]
            this.listState = await States.params({ logic_id: logicData[0].id }).get()
            this.dsbBtn = false
          } else {
            console.warn(`реестр в бизнес-логики не найден ID: ${this.value.object_id}`)
            this.$message({
              showClose: true,
              duration: 0,
              message: `Для формулы необходимо добавить реест object_${val}_ в бизнес-логику!`,
              type: 'error'
            })
            this.dsbBtn = true
            return false
          }
          let states = await States.params({ logic_id: logicData[0].id }).get()
          this.listState = states
          let registryTree = await Registry.params({ parent_id: val, show_children: true }).$get()
          this.listField = registryTree
        }
      }
    },
    'value.name': {
      handler: function (val, oldval) {
        if (val === '') {
          this.$emit('dslBtn', true)
        } else {
          this.$emit('dslBtn', false)
        }
      }
    },
    'value.interaction_type': {
      handler: function (val, oldval) {
        if (val === '') this.value.interaction_type = null
      }
    },
    'value.icon': {
      handler: function (val, oldval) {
        if (val === '') this.value.icon = null
      }
    }
  },
  computed: {
    isShowListStates () {
      if (this.value.condition_type === 'state') {
        return true
      }
      return false
    }
  },
  methods: {
    calcTitle (name, type) {
      this.notifyFormula.name = name
      this.notifyFormula.type = type
      this.dialogNotify = true
    },
    getValueFormula (val) {
      console.log('getValueFormula', val)
      if (val.id) {
        this.$refs[val.typeFormula].innerHTML = val.res
      } else {
        this.$refs[val.typeFormula].textContent = val.res
      }
      this.save()
    }
  },
  async mounted () {
    let registryTree = await Registry.params({ parent_id: this.value.object_id, show_children: true }).$get()
    this.listField = registryTree
    let logicData = await new Logic().custom('/logics').params({ registry_id: this.value.object_id }).get()
    if (logicData[0]) {
      this.propslocal.businesslogic = logicData[0]
      this.listState = await States.params({ logic_id: logicData[0].id }).get()
    } else {
      console.error(`реестр в бизнес-логики не найден ID: ${this.value.object_id}`)
      return false
    }
    if (this.value.title_formula_id) {
      this.propslocal.getAnswer = await FormulasHttp.find(this.value.title_formula_id)
      if (this.propslocal.getAnswer.is_dsl) {
        this.$refs.titleTextarea.innerHTML = this.propslocal.getAnswer.dsl_statement
      } else {
        this.$refs.titleTextarea.textContent = this.propslocal.getAnswer.sql_statements.select
      }
    }
    if (this.value.content_formula_id) {
      this.propslocal.getNotifyContent = await FormulasHttp.find(this.value.content_formula_id)
      if (this.propslocal.getNotifyContent.is_dsl) {
        this.$refs.contentTextarea.innerHTML = this.propslocal.getNotifyContent.dsl_statement
      } else {
        this.$refs.contentTextarea.textContent = this.propslocal.getNotifyContent.sql_statements.select
      }
    }
    try {
      let registry = await Registry.params({ is_object: true }).get()
      this.listRelatesRegistry = registry.data
      // this.listUser = await Users.params({ fields: 'id,name,surname,midname' }).$get()
      this.listUser = await APIClient.shared.request(new UserAPI.GetUsers([]))
      let objId = await this.$http.get(`${this.$config.api}/objecteditor/entities?properties[is_users_table]=true&show_children=true`)
      let logicId = await this.$http.get(`${this.$config.api}/logiceditor/logics?registry_id=${objId.data.data[0].id}`)
      let listStates = await this.$http.get(`${this.$config.api}/logiceditor/states?logic_id=${logicId.data[0].id}`)
      this.listStatesAroundUser = listStates.data
      let periodTypes = await this.$http.get(`${this.$config.api}/notificationeditor/notifications/period_types`)
      let periodTypesLocale = this.$locale.notify_editor.periods
      this.periods = periodTypes.data.map(el => ({ id: el.id, name: periodTypesLocale[el.name] || el.name }))
    } catch (error) {
      console.log(error)
    }
    this.listTag = Icons.map(el => ({ value: el, label: el }))
  }
}
</script>

<style>
.notifyComponent .el-select {
  width: 100%;
}
.notifyComponent .el-form-item {
  margin-bottom: 0;
}
.notifyComponent .el-dialog .el-dialog__body {
  height: 85vh;
}
.notifyComponent .el-form-item {
  margin-bottom: 15px;
}
.notifyComponent .el-form--label-top .el-form-item__label {
  padding: 0 0 5px;
}
.notifyComponent .form-group-item {
  margin-bottom: 20px;
}
.notifyComponent .form-group-item .el-form-item {
  margin-bottom: 5px;
}
.formula-box {
  margin-bottom: 15px;
}

.formula-box > h4 {
  margin: 0;
  padding-bottom: 10px;
  line-height: 32px;
}

.formula-box > div {
  margin-bottom: 10px;
  border: 1px solid #DCDFE6;
  min-height: 70px;
  border-radius: 4px;
  padding: 10px;
  font-family: 'Courier New', sans-serif;
  color: #409EFF;
}

.notifyComponent .el-form-item.required label.el-form-item__label:before {
  content: '* ';
  color: #ff4949;
  font-weight: bold;
}
.notifyComponent .formula-box > div span {
  color: #444;
}
</style>
